const fillupAds = () => ({
  bounding: null,
  ads: [],
  adsAvailable: {
    'halfpage': bergfex.ads.halfpage,
    'content-fillup': bergfex.ads.content,
  },
  gap: 16,

  async init() {
    this.bounding = this.$el.getBoundingClientRect()

    if (this.bounding.height <= 0 || this.bounding.width <= 0) {
      return
    }

    if (!this.$el.getClientRects().length) {
      return
    }

    const smallestAd = this.getSmallestAd()
    if (this.bounding.height < smallestAd.height || this.bounding.width < smallestAd.width) {
      return
    }

    await this.$nextTick()

    this.setAdScheme()
  },

  setAdScheme() {
    const smallestAd = this.getSmallestAd()
    let availableHeight = this.bounding.height

    while (availableHeight >= smallestAd.height) {
      for (const [key, ad] of Object.entries(this.adsAvailable)) {
        if (ad.height > availableHeight) {
          continue
        }

        availableHeight = availableHeight - ad.height
        this.ads.push(key)
      }
    }
  },

  getSmallestAd() {
    return Object.values(this.adsAvailable).reduce((acc, current) => acc.height < current.height ? acc : current)
  },

  replaceZonename() {
    const targetZone = this.$el.dataset.zone

    if (targetZone === 'content-fillup') {
      return
    }

    const element = this.$el.querySelector('.partner')
    element.dataset.zoneid = bergfex.promotionZones.halfpage
    element.className = element.className.replaceAll('contentfillup', targetZone)
  },

  load() {
    this.replaceZonename()
    bergfex.contentAds.initContentAd(this.$el.querySelector('.partner'))
  },
})

export default fillupAds
